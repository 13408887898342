import React from "react";
import masterCard from "../assets/img/mastercard.png";
import paypal from "../assets/img/paypal.png";
import visa from "../assets/img/visa.png";
import { 
  Container,Row, Col, Card, CardBody, CardTitle, CardText, Button, Form,
  FormGroup, Label, Input, FormText
} from "reactstrap";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <Container>
        {/* Add To Cart */}
        <Row>
          <Col lg='8' md='12'>
            <Form className='cart_totals checkout light_form mb-4'>
              <FormText tag='h4' color='black'>Billing Info</FormText>

              <Row form>
                <Col lg='6' md='6'>
                  <FormGroup>
                    <Label for='firstName'>First Name</Label>
                    <Input id='firstName' type='text' />
                  </FormGroup>
                </Col>
                <Col lg='6' md='6'>
                  <FormGroup>
                    <Label for='lastName'>Last Name</Label>
                    <Input id='lastName' type='text' />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col lg='6' md='6'>
                  <FormGroup>
                    <Label for='email'>Email Address</Label>
                    <Input id='email' type='email' />
                  </FormGroup>
                </Col>
                <Col lg='6' md='6'>
                  <FormGroup>
                    <Label for='phoneNumber'>Phone Number</Label>
                    <Input id='lastName' type='number' />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col lg='6' md='6'>
                  <FormGroup>
                    <Label for='address'>Address</Label>
                    <Input id='address' type='text' />
                  </FormGroup>
                </Col>
                <Col lg='6' md='6'>
                  <FormGroup>
                    <Label for='townCity'>Town/City</Label>
                    <Input id='townCity' type='text' />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col lg='6' md='6'>
                  <FormGroup>
                    <Label for='stateCountry'>State/Country</Label>
                    {/* Todo: match with search / placeholder */}
                    <Input id='stateCountry' type='select' placeholder="Choose Country">
                      <option>United State</option>
                      <option>United Kingdom</option>
                      <option>India</option>
                      <option>China</option>
                      <option>Canada</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg='6' md='6'>
                  <FormGroup>
                    <Label for='zipPostalCode'>Zip/Postal Code</Label>
                    <Input id='zipPostalCode' type='text' />
                  </FormGroup>
                </Col>
              </Row>

              <Col>
                <FormGroup>
                  <Label check className='checkbox-custom-label'>
                    <Input type='checkbox' /> 
                    Skip to a different address?
                  </Label>
                </FormGroup>
              </Col>
            </Form>

            <Form className='cart_totals checkout light_form'>
              <FormText color='black' tag='h4'>Select Payment Methods</FormText>
              <Row form>
                <Col lg='4' md='4' sm='4'>
                  <FormGroup>
                    <div class="choose_payment_mt active">
											<img src={masterCard} alt="" />
										</div>
                  </FormGroup>
                </Col>
                <Col lg='4' md='4' sm='4'>
                  <FormGroup>
                    <div class="choose_payment_mt active">
                      <img src={paypal} alt="" />
										</div>
                  </FormGroup>
                </Col>
                <Col lg='4' md='4' sm='4'>
                  <FormGroup>
                    <div class="choose_payment_mt active">
                      <img src={visa} alt="" />
										</div>
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col lg='12' md='12'>
                  <FormGroup>
                    <Label for='cardNumber'>Card Number</Label>
                    <Input id='cardNumber' type='number' />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col lg='7' md='7'>
                  <FormGroup>
                    <Label for='cardHolder'>Card Holder</Label>
                    <Input id='cardHolder' type='text' />
                  </FormGroup>
                </Col>
                <Col lg='3' md='3'>
                  <FormGroup>
                    <Label for='expiryDate'>Expiry Date</Label>
                    <Input id='expiryDate' type='date' />
                  </FormGroup>
                </Col>
                <Col lg='2' md='2'>
                  <FormGroup>
                    <Label for='cvc'>CVC</Label>
                    <Input id='cvc' type='text' placeholder='cvc'/>
                  </FormGroup>
                </Col>
              </Row>
              <Button color='danger' block>Proceed To Checkout</Button>
            </Form>
          </Col>
          {/* End of Add To Cart */}

          {/* Total Cart */}
          <Col lg='4' md='12'>
            <Card className='cart_totals checkout'>
              <CardBody>
                <CardTitle tag='h4'>Order Summary</CardTitle>
                <CardText>
                  <ul class="cart_list">
                    <li>Base price<strong>$140.00</strong></li>
                    <li>Discount<strong>$10.00</strong></li>
                    <li>CGST<strong>$10.00</strong></li>
                    <li>SGST<strong>$10.00</strong></li>
                  </ul>
                  <div class="flex_cart">
                    <div class="flex_cart_1">
                      Total Cost
                    </div>
                    <div class="flex_cart_2">
                      $170.00
                    </div>
                  </div>
                </CardText>
                <Button 
                  outline 
                  block 
                  style={{borderColor: "transparent"}} 
                  className="btn checkout_btn"
                >
                  Proceed To Checkout
                </Button>
              </CardBody>
            </Card>
          </Col>
          {/* End of Total Cart */}
        </Row>
      </Container>
    )
  }
}

export default CheckoutForm;
