import NavBar from "../components/NavBar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import SectionHeader from "../components/SectionHeader";
import AddToCartList from "../components/AddToCartList";

function AddToCart() {
  return(
    <div>
      <NavBar />
      <SectionHeader 
        titleName='Add To Cart' 
        itemName='Add To cart'
      />
      <AddToCartList />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default AddToCart;
