import React from 'react'
import {
    Button, Container, Row, Col, Card,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback,
} from 'reactstrap'
import Accordion from "react-bootstrap/Accordion";
import {courseCircullumData} from './CourseDetailData';





export default class CoursePart extends React.Component{
    render(){
        return(
         <div>
                    <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0"> 
                        <h6 class="mb-0 accordion_title">Part 01: How To Learn Web Designing Step by Step</h6>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                          <div class="card-body pl-3 pr-3">
                                                      <ul class="lectures_lists">
                                                          <li><div class="lectures_lists_title"><i class="ti-control-play"></i>Lecture: 01</div>Web Designing Beginner</li>
                                                          <li><div class="lectures_lists_title"><i class="ti-control-play"></i>Lecture: 02</div>Startup Designing with HTML5 & CSS3</li>
                                                          <li><div class="lectures_lists_title"><i class="ti-control-play"></i>Lecture: 03</div>How To Call Google Map iFrame</li>
                                                          <li class="unview"><div class="lectures_lists_title"><i class="ti-control-play"></i>Lecture: 04</div>Create Drop Down Navigation Using CSS3</li>
                                                          <li class="unview"><div class="lectures_lists_title"><i class="ti-control-play"></i>Lecture: 05</div>How to Create Sticky Navigation Using JS</li>
                                                      </ul>
                                                  </div>
                                                  </Card.Body> 
                    </Accordion.Collapse>
                 </Card> 
                </div>  
        )
    }}
