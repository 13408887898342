import './Testimonial.scss';
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback,
} from 'reactstrap'
import React from 'react'
// import {testimonialData} from './TestimonialData';
import TestiCarousel from './TestiCarousel';



export class TestimonialShare extends React.Component{
  constructor(props){
    super(props)
  }
  ;
  render(){
    const listShowing = this.props;
    return(
      //  {/* 1st detail */}
       <div className="testimonial-detail">
       <div className="client-detail-box">
         <div className="pic">
           <img src={listShowing.ReviewerImage} alt=""/>
         </div>
         <div className="client-detail">
           <h3 className="testimonial-title">{listShowing['testimonial-title']}</h3>
           <span className="post">{listShowing['post']}</span>
         </div>
       </div>
       <p className="description">
       {listShowing['description']}  
       </p>
     </div>
    )
  }
}

//  ============================ Testimonial Start ==================================//
class Testimonial extends React.Component{
	render(){
		return (
      //change section to div//div into Container// turn  url into string 
      <section className="testimonial-section">
        <Container>
          <div className="sec-heading center">
            <p>What People Say?</p>
            <h2><span className="theme-cl">Reviews</span> By Our Success & Top Students</h2>
          </div>
          <div id="testimonials_style" className="slick-carousel-3 arrow_middle">
            <TestiCarousel /> 
          </div>
        </Container>
      </section>	
			
		)
	}
}
//   <!-- ============================ Testimonial End ================================== -->
		

export default Testimonial;