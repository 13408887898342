import React from "react";
import './Feature.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Row, Container, Button, Col, 
  Form, FormGroup, Label, Input, FormText, FormFeedback, InputGroup, InputGroupAddon
} from "reactstrap";


class Feature extends React.Component {
    constructor(props) {
        super(props);
    }

  render() {
    return(
      <Container className='parent-container'>
        <Row className='parent-row'>
          <Col lg="6" md="6" sm="12">
            <h2 className='title'>
              Know About &#8203;
              <span className='highlight-text'>
                E-Learn
              </span>
              &#8203; Learning Platform
            </h2>
            <Row className='child-row'>
              <Col xs='2'>
                <img
                  className='img-wrapper' 
                  src="https://via.placeholder.com/40x45"
                ></img>
              </Col>
              <Col xs='10'>
                <h4 className='subtitle'>
                  Nemo Enim Ipsam Voluptatem Quia.
                </h4>
                <p className='content'>
                  No one rejects, dislikes, or avoids pleasure itself, because it is pleasure
                </p>
              </Col>
            </Row>
            <Row className='child-row'>
              <Col xs='2'>
                <img
                  className='img-wrapper' 
                  src="https://via.placeholder.com/40x45"
                ></img>
              </Col>
              <Col xs='10'>
                <h4 className='subtitle'>
                  Nemo Enim Ipsam Voluptatem Quia.
                </h4>
                <p className='content'>
                  No one rejects, dislikes, or avoids pleasure itself, because it is pleasure
                </p>
              </Col>
            </Row>
            <Row className='child-row'>
              <Col xs='2'>
                <img
                  className='img-wrapper' 
                  src="https://via.placeholder.com/40x45"
                ></img>
              </Col>
              <Col xs='10'>
                <h4 className='subtitle'>
                  Nemo Enim Ipsam Voluptatem Quia.
                </h4>
                <p className='content'>
                  No one rejects, dislikes, or avoids pleasure itself, because it is pleasure
                </p>
              </Col>
            </Row>
            <a href="AboutUs" class="btn btn-danger text-uppercase rounded shadow mb-5">
              Know More <i class="fas fa-angle-double-right"></i>
            </a>
          </Col>

          <Col lg="6" md="6" sm="12">
            <img
              className='img-wrapper' 
              src="https://via.placeholder.com/550x490"
            />
          </Col>
        </Row>
      </Container>
    )
  }

}

  export default Feature;
