import React from 'react';
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback,
} from 'reactstrap'
import {courseHeaderData} from './CourseDetailData';
import './CourseHeader.scss'



export default class CourseHeader extends React.Component{
    constructor(props){
        super(props)
        this.state={
            courseHeaderData:courseHeaderData['Ruby on Rails Program']
        }
    }
    render(){
        return(
            // <!-- ============================ Course Header Info Start================================== -->
            <div className="image-cover ed_detail_head lg theme-ov" style={{background:"#f4f4f4", url:('https://via.placeholder.com/1920x650')}} data-overlay="9">
                <Container>
                    <Row>
                        
                        <Col lg='7' md='7'>
                            <div className="ed_detail_wrap light">
                                <ul className="cources_facts_list">
                                    <li className="facts-1">{this.state.courseHeaderData['cources-facts-list-facts-1']}</li>
                                    <li className="facts-5">{this.state.courseHeaderData['cources-facts-list-facts-5']}</li>
                                </ul>
                                <div className="ed_header_caption">
                                    <h2 className="ed_title">{this.state.courseHeaderData['course-title']}</h2>
                                    <ul>
                                        <li><i className="ti-calendar"></i>{this.state.courseHeaderData['calendar']}</li>
                                        <li><i className="ti-control-forward"></i>{this.state.courseHeaderData['course-total']}</li>
                                        <li><i className="ti-user"></i>{this.state.courseHeaderData['user']}</li>
                                    </ul>
                                </div>
                                <div className="ed_header_short">
                                    <p>{this.state.courseHeaderData['course-briefing']}</p>
                                </div>
                                
                                <div className="ed_rate_info">
                                    <div className="star_info">
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="review_counter">
                                        <strong className="high">{this.state.courseHeaderData['rating']}</strong> {this.state.courseHeaderData['reviews']}
                                    </div>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            // <!-- ============================ Course Header Info End ================================== -->
        )
    }
}