import './Newsletter.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label,Input, InputGroup, Fonttext, FontFeedback,
} from 'reactstrap'
import React from 'react'

// <!-- ============================== Start Newsletter ================================== -->
class Newsletter extends React.Component{
	render(){
		return (
				//change section to div//div into Container// turn  url into string 
				<section className="newsletter-bg">
					<Container>
						<div className="text-center text-white">
							<h2 className="text-white">Join Thousand of Happy Students!</h2>
							<p>Subscribe our newsletter & get latest news and updation!</p>
						</div>
						<Form>
							<InputGroup>
								<Input type="email" placeholder="Your Email Address" required="required" />
								<Button color="danger">Get Started</Button>
							</InputGroup>
						</Form>
					</Container>
				</section>
			)
		}
	}
// <!-- ================================= End Newsletter =============================== --> 

export default Newsletter;