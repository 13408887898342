import NavBar from "../components/NavBar";
import SectionHeader from "../components/SectionHeader";
import FAQ from "../components/FAQ";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

function FAQsPage() {
  return(
    <div>
      <NavBar />
      <SectionHeader 
        titleName='FAQs' 
        itemName='FAQs'
      />
      <FAQ />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default FAQsPage;
