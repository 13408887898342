export const courseHeaderData= {
  'Ruby on Rails Program':{
    'cources-facts-list-facts-1':'SEO',
    'cources-facts-list-facts-5':'Design',
		'course-title':'Ruby on Rails Program',
    'calendar':'10 - 20 weeks',
    'course-total':'102 Lectures',
    'user':'502 Student Enrolled',
    'course-briefing':'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore. veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
    'rating':'4.7',
    'reviews':'3572 Reviews'
}}
export const courseDetailData= {
  'Ruby on Rails Program':{
    'Course Overview':['At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.',
                      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'],
    'Requirements':['At vero eos et accusamus et iusto odio dignissimos ducimus','At vero eos et accusamus et iusto odio dignissimos ducimus','At vero eos et accusamus et iusto odio dignissimos ducimus','At vero eos et accusamus et iusto odio dignissimos ducimus','At vero eos et accusamus et iusto odio dignissimos ducimus']
}
}
export const courseCircullumData=[
  {
      name:'Part 01:How To Learn Web Designing Step by Step',
      lecture:[
        'Lecture: 01   Web Designing Beginner',
        'Lecture: 02   Startup Designing with HTML5 & CSS3',
        'Lecture: 03   How To Call Google Map iFrame',
        'Lecture: 04   Create Drop Down Navigation Using CSS3',
        'Lecture: 05   How to Create Sticky Navigation Using JS']

  },
  {
      name:'Part 02: Learn Web Designing in Basic Level',
      lecture:[
        'Lecture: 01   Web Designing Beginner',
        'Lecture: 02   Startup Designing with HTML5 & CSS3',
        'Lecture: 03   How To Call Google Map iFrame',
        'Lecture: 04   Create Drop Down Navigation Using CSS3',
        'Lecture: 05   How to Create Sticky Navigation Using JS']


  },
  {
      name:'Part 03: Learn Web Designing in Advance Level',
      lecture:[
        'Lecture: 01   Web Designing Beginner',
        'Lecture: 02   Startup Designing with HTML5 & CSS3',
        'Lecture: 03   How To Call Google Map iFrame',
        'Lecture: 04   Create Drop Down Navigation Using CSS3',
        'Lecture: 05   How to Create Sticky Navigation Using JS']


  },
  {
      name:'Part 04: How To Become Succes in Designing & Development?',
      lecture:[
        'Lecture: 01   Web Designing Beginner',
        'Lecture: 02   Startup Designing with HTML5 & CSS3',
        'Lecture: 03   How To Call Google Map iFrame',
        'Lecture: 04   Create Drop Down Navigation Using CSS3',
        'Lecture: 05   How to Create Sticky Navigation Using JS']
  },
]
export const courseRatingData= {
  'Ruby on Rails Program':{
    'rating-overview':'4.2'
  }
}

export const instructorData= {
  'Ruby on Rails Program':{
    'img':'https://via.placeholder.com/500x500',
    'name':'Jonathan Campbell',
    'video-total':'72 Videos',
    'course-total':'102 Lectures',
    'exp.':'Exp. 4 Year',
    'detail':'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.'
  }
}
export const courseReviewData=[
  {'ReviewImage':"https://via.placeholder.com/500x500",
  "name":'Josaph Manrty',
  'date':'27 Oct 2019',
  'review-count':'4.9',
  'review':" Commodo est luctus eget. Proin in nunc laoreet justo volutpat blandit enim. Sem felis, ullamcorper vel aliquam non, varius eget justo. Duis quis nunc tellus sollicitudin mauris. ",
  'ti-thumb-up': '12',
  'ti-thumb-down':'1',
  'ti-heart':'07'
      },
  {'ReviewImage':"https://via.placeholder.com/500x500",
  "name":'Rita Chawla',
  'date':'2 Nov May 2019',
  'review-count':'3.7',
  'review':" Commodo est luctus eget. Proin in nunc laoreet justo volutpat blandit enim. Sem felis, ullamcorper vel aliquam non, varius eget justo. Duis quis nunc tellus sollicitudin mauris. ",
  'ti-thumb-up': '12',
  'ti-thumb-down':'1',
  'ti-heart':'07'
      },
  {'ReviewImage':"https://via.placeholder.com/500x500",
  "name":'Adam Wilsom',
  'date':'10 Nov 2019',
  'review-count':'4.2',
  'review':" Commodo est luctus eget. Proin in nunc laoreet justo volutpat blandit enim. Sem felis, ullamcorper vel aliquam non, varius eget justo. Duis quis nunc tellus sollicitudin mauris. ",
  'ti-thumb-up': '12',
  'ti-thumb-down':'1',
  'ti-heart':'07'
      },
]

export const sidebarData= {
    'Ruby On Rails Program':{
       'img':'https://via.placeholder.com/1920x650',
       'alt' : '7.jpg',
       'link':'https://www.youtube.com/watch?v=A8EI6JaFbv4',
       'Acctual Price':'$ 149.00',
       'Course Features':[
          'Fully Programming',
          'Help Code to Code',
          'Free Trial 7 Days',
          'Unlimited Videos',
          '24x7 Support'
        ],
       'student':'1740',
       'lectures':'10',
       'quizzes':'4',
       'duration':'60 hours',
       'skill level':'Beginner',
       'language':'English',
       'assessment':'Yes'
    }
}

