import React from 'react'
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback,
} from 'reactstrap'
export default class SubmitReview extends React.Component{
    render(){
        return(
                    <div className="edu_wraper border">
                    <h4 className="edu_title">Submit Reviews</h4>
                    <div className="review-form-box form-submit">
                        <form>
                            <Row>
                                
                                <Col lg='6' md='6' sm='12'>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input className="form-control" type="text" placeholder="Your Name"/>
                                    </div>
                                </Col>
                                
                                <Col lg='6' md='6' sm='12'>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input className="form-control" type="email" placeholder="Your Email"/>
                                    </div>
                                </Col>
                                
                                <Col lg='12' md='12' sm='12'>
                                    <div className="form-group">
                                        <label>Review</label>
                                        <textarea className="form-control ht-140" placeholder="Review"></textarea>
                                    </div>
                                </Col>
                                
                                <Col lg='12'md='12' sm='12'>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-theme">Submit Review</button>
                                    </div>
                                </Col>
                                
                            </Row>
                        </form>
                    </div>
                </div>
        )
    }
}