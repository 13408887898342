import React from "react";
import search from "./../assets/custom/img/search.svg";
import './Hero.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Row, Container, Button, Col, 
  Form, FormGroup, Label, Input, FormText, FormFeedback, InputGroup, InputGroupAddon
} from "reactstrap";


class Hero extends React.Component {
    constructor(props) {
        super(props);
    }

  render(){
    return(
      <div className='background'>
        <Container className='parent-container'>
          <Row className="row align-items-center">
            <Col lg="6" md="6" sm="12" className='col-wrapper mb-3'>
              <h1 className='title'>
                Learn on your<br/><span> schedule</span>
              </h1>
              <p className='content'>
                Study any topic, anytime. Choose from thousands of expert-led courses now.
              </p>

              <Form>
                <InputGroup>
                  <Input 
                    type="text" 
                    className="form-control form-search" 
                    placeholder="Keyword" 
                  />
                  <Button color='danger'>Go</Button>
                </InputGroup>

              </Form>
            </Col>

            <Col lg="6" md="6" sm="12">
              <img 
                className='img-wrapper' 
                src="https://via.placeholder.com/500x600"
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Hero;
