import React from 'react'
import {Row,Col,
	Breadcrumb,	BreadcrumbItem,
	Card, CardHeader, CardFooter, CardBody,
  CardTitle, CardText, Button,UncontrolledCarousel
} from 'reactstrap'
import CardCarousel from './Carousel'
import './DashBoard.scss'

class ProfileInfo extends React.Component{
	render(){
		return(
			<Row className="align-items-center">
				<Col lg='12' md='12' sm='12'>
					<Card className="dashboard_container">
						<div className="dashboard_container_body p-4">
							<div className="viewer_detail_wraps">
								<div className="viewer_detail_thumb">
									<img src="https://via.placeholder.com/500x500" className="img-fluid" alt="" />
									<div className="viewer_status">{this.props.ViewerStatus}</div>
								</div>
								<div className="caption">
									<div className="viewer_package_status">{this.props.ViewerPackageStatus}</div>
									<div className="viewer_header">
										<h4>{this.props.ViewerName}</h4>
										<span className="viewer_location">{this.props.ViewerLocation}</span>
										<ul>
											<li><strong>{this.props.ViewerPoint}</strong> Points</li>
											<li><strong>{this.props.ViewerClassCompleted}</strong> Classes Completed</li>
											<li><strong>{this.props.ViewerLessonCompleted}</strong> Lessons Completed</li>
										</ul>
									</div>
									<div className="viewer_header">
										<ul className="badge_info">
											<li className="started"><i className="ti-rocket"></i></li>
											<li className="medium"><i className="ti-cup"></i></li>
											<li className="platinum"><i className="ti-thumb-up"></i></li>
											<li className="elite unlock"><i className="ti-medall"></i></li>
											<li className="power unlock"><i className="ti-crown"></i></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</Card>
				</Col>
			</Row>
		)
	}
}
class ContinueWatching extends React.Component{
	render(){
		return(
			<Col lg='12' md='12' sm='12' className='d-flex justify-content-between'>
					<h4>Continue Watching</h4>
					<div className='slideshow_contro mr-3'>
						<Button className='m-1 p-1'><i className='ti-arrow-left'></i></Button>
						<Button className='m-1 p-1'><i className='ti-arrow-right'></i></Button>
					</div>
					
			</Col>
		)
	}
}
class SingleSlide extends React.Component{
	render(){
		return(
			<Col className="singles_items" lg='4' md='4' sm='4'>
				<div className="edu-watching">
					<div className="property_video sm">
						<div className="thumb">
							<img className="pro_img img-fluid w100" src={this.props.img} alt="7.jpg" />
							<div className="overlay_icon">
								<div className="bb-video-box">
									<div className="bb-video-box-inner">
										<div className="bb-video-box-innerup">
											<a href="https://www.youtube.com/watch?v=A8EI6JaFbv4" 
												data-toggle="modal" 
												data-target="#popup-video" 
												className="theme-cl">
												<i className="ti-control-play"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="edu_duration">{this.props.duration}</div>
					</div>
					<div className="edu_video detail">
						<div className="edu_video_header">
							<h4><a href="#">{this.props.title}</a></h4>
						</div>
						<div className="edu_video_bottom">
							<div className="edu_video_bottom_left">
								<span>{this.props.caption}</span>	
							</div>
							<div class="edu_video_bottom_right">
								<i class="ti-desktop"></i>{this.props.category}
							</div>
						</div>
					</div>
				</div>
			</Col>
		)
	}
}

class NewCourses extends React.Component{
	render(){
		return(
			<div>
				<Row>
					<Col lg='12' md='12' sm='12' tag='h4'>New Courses</Col>
				</Row>
				<Row>
				<SingleSlide 
					img='"https://via.placeholder.com/700x500"' 
					category='Designing' 
					title='Tableau 2020 A-Z:Hands-On Tableau Training For Data Science!'
					duration='20:30' 
					caption='Lession 05 of 12' />
				<SingleSlide 
					img='"https://via.placeholder.com/700x500"' 
					category='Designing' 
					title='Tableau 2020 A-Z:Hands-On Tableau Training For Data Science!'
					duration='20:30' 
					caption='Lession 05 of 12' />
				<SingleSlide 
					img='"https://via.placeholder.com/700x500"' 
					category='Designing' 
					title='Tableau 2020 A-Z:Hands-On Tableau Training For Data Science!'
					duration='20:30' 
					caption='Lession 05 of 12' />
				</Row>
			</div>
			
		)
	}
}
export default class ProfileRight extends React.Component{
	render(){
		return(
			<Col lg='9' md='9' sm='12'>
					<Breadcrumb className='breadcrumb justify-content-start p-0 mt-4 mb-4'>
              <BreadcrumbItem className='breadcrumb-item'><a href='/'>Home</a></BreadcrumbItem>
              <BreadcrumbItem className='breadcrumb-item' active>My Profile</BreadcrumbItem>
					</Breadcrumb>
					<ProfileInfo 
						ViewerStatus='pro'
						ViewerPackageStatus='Expire in 5 Days'
						ViewerName='Marrion Willsoriam'
						ViewerLocation = 'Montreal, Canada'
						ViewerPoint = '112'
						ViewerClassCompleted = '9'
						ViewerLessonCompleted = '18'
					/>
					<Row>
						<ContinueWatching />
							<SingleSlide 
								img='"https://via.placeholder.com/700x500"' 
								category='Designing' 
								title='Tableau 2020 A-Z:Hands-On Tableau Training For Data Science!'
								duration='20:30' 
								caption='Lession 05 of 12' />
							<SingleSlide 
								img='"https://via.placeholder.com/700x500"' 
								category='Designing' 
								title='Tableau 2020 A-Z:Hands-On Tableau Training For Data Science!'
								duration='20:30' 
								caption='Lession 05 of 12' />
							<SingleSlide 
								img='"https://via.placeholder.com/700x500"' 
								category='Designing' 
								title='Tableau 2020 A-Z:Hands-On Tableau Training For Data Science!'
								duration='20:30' 
								caption='Lession 05 of 12' />
					</Row>
					<NewCourses />
			</Col>
		)
	}
}




