import React from "react";
import { 
  Card, CardBody, CardTitle, CardText, Col, Container, 
  Form, FormGroup, Row, Button, Input, Table
} from "reactstrap";

class AddToCartList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div>
        <section className='pt-0'>
          <Container>
            <Row>
              <Col lg='8' md='12'>

                {/* Table */}
                <Table responsive className='add_to_cart'>
                  <thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Title</th>
											<th scope="col">Price</th>
											<th scope="col">Quantity</th>
											<th scope="col">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td><div className="tb_course_thumb"><img src="https://via.placeholder.com/700x500" class="img-fluid" alt="" /></div></td>
											<th>Besic Web Design<span className="tb_date">18 july 2020</span></th>
											<td><span className="wish_price theme-cl">$149.00</span></td>
											<td><Input type="number" className="form-control qty" placeholder="1" /></td>
											<td><Button className="btn-remove">Remove</Button></td>
										</tr>
										<tr>
											<td><div className="tb_course_thumb"><img src="https://via.placeholder.com/700x500" class="img-fluid" alt="" /></div></td>
											<th>Advance Java Script<span className="tb_date">15 july 2020</span></th>
											<td><span className="wish_price theme-cl">$129.00</span></td>
											<td><Input type="number" className="form-control qty" placeholder="1" /></td>
											<td><Button className="btn-remove">Remove</Button></td>
										</tr>
										<tr>
											<td><div className="tb_course_thumb"><img src="https://via.placeholder.com/700x500" class="img-fluid" alt="" /></div></td>
											<th>The Computer Science<span class="tb_date">13 july 2020</span></th>
											<td><span className="wish_price theme-cl">$125.00</span></td>
											<td><Input type="number" className="form-control qty" placeholder="1" /></td>
											<td><Button className="btn-remove">Remove</Button></td>
										</tr>
										<tr>
											<td><div className="tb_course_thumb"><img src="https://via.placeholder.com/700x500" class="img-fluid" alt="" /></div></td>
											<th>Business Analysis<span className="tb_date">12 july 2020</span></th>
											<td><span className="wish_price theme-cl">$179.00</span></td>
											<td><Input type="number" className="form-control qty" placeholder="1" /></td>
											<td><Button className="btn-remove">Remove</Button></td>
										</tr>
										<tr>
											<td><div className="tb_course_thumb"><img src="https://via.placeholder.com/700x500" class="img-fluid" alt="" /></div></td>
											<th>The Computer Highnext<span className="tb_date">11 july 2020</span></th>
											<td><span className="wish_price theme-cl">$180.00</span></td>
											<td><Input type="number" className="form-control qty" placeholder="1" /></td>
											<td><Button className="btn-remove">Remove</Button></td>
										</tr>
									</tbody>
                </Table>
                {/* End of Table */}

                {/* Buttons */}
                <Form>
                  <FormGroup>
                    <Row form className='align-items-center'>
                      <Col>
                        <Input 
                          className='form-control' 
                          type='text' 
                          placeholder='Coupon Code' 
                        />
                      </Col>
                      <Col>
                        <Button 
                          className='btn-theme2' 
                          color='danger'
                        >
                          Apply Coupon
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row form className='align-items-center'>
                      <Col>
                        <Button 
                          className='empty_btn' 
                          color='danger'
                        >
                          Empty Cart
                        </Button>
                        <Button 
                          className='btn-theme2 ml-2' 
                          color='danger'
                          disabled
                        >
                          Update Cart
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
                {/* End of Buttons */}

              </Col>
              
              {/* Bill Summary */}
              <Col lg='4' md='12'>
                <Card className='cart_totals checkout'>
                  <CardBody>
                    <CardTitle tag='h4'>Billing Summary</CardTitle>
                    <CardText>
                      <ul class="cart_list">
                        <li>Base price<strong>$140.00</strong></li>
                        <li>Discount<strong>$10.00</strong></li>
                        <li>CGST<strong>$10.00</strong></li>
                        <li>SGST<strong>$10.00</strong></li>
                      </ul>
                      <div class="flex_cart">
                        <div class="flex_cart_1">
                          Total Cost
                        </div>
                        <div class="flex_cart_2">
                          $170.00
                        </div>
                      </div>
                    </CardText>
                    {/* Todo: direct to checkout page if login successfully; tentatively use <a> link to checkout page */}
                    {/* <Button 
                      outline 
                      block 
                      style={{borderColor: "transparent"}} 
                      className="btn checkout_btn"
                    >
                      Proceed To Checkout
                    </Button> */}
                      <a href="/Checkout" className="btn btn-danger btn-block"> Proceed To Checkout </a>
                  </CardBody>
                </Card>
              </Col>
              {/* End of Bill Summary */}

            </Row>
          </Container>
        </section>
      </div>
    )
  }
}

export default AddToCartList;
