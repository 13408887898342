import {Course_Features, sidebarData} from './CourseDetailData'
import React from 'react'
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback,
} from 'reactstrap'

class Sidebar extends React.Component{
    constructor(props){
        super(props)
        this.state={
            sidebarData:sidebarData['Ruby On Rails Program']
        }
    }
    render(){
        return(
                <Col lg='4' md='4'>
					
                    <div className="ed_view_box border">
                    
                        <div className="property_video sm">
                            <div className="thumb">
                                <img className="pro_img img-fluid w100" src={sidebarData['Ruby On Rails Program']['img']} alt="7.jpg"/>
                                <div className="overlay_icon">
                                    <div className="bb-video-box">
                                        <div className="bb-video-box-inner">
                                            <div className="bb-video-box-innerup">
                                                <a href="https://www.youtube.com/watch?v=A8EI6JaFbv4" data-toggle="modal" data-target="#popup-video" className="theme-cl"><i className="ti-control-play"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="ed_view_price">
                            <span>Acctual Price</span>
                            <h2 className="theme-cl">{sidebarData['Ruby On Rails Program']['Acctual Price']}</h2>
                        </div>
                        <div className="ed_view_features">
                            <span>Course Features</span>
                         
                            <ul>
                            {this.state.sidebarData['Course Features'].map((item,index)=>{
                                console.log(sidebarData['Ruby On Rails Program']['Course Features'])
                                return(
                                    <li><i className="ti-angle-right"></i>{this.state.sidebarData['Course Features'][index]}</li>
                                )
                            })}
                                
                            </ul>
                        </div>
                        <div className="ed_view_link">
                            <a href="/AddToCart" className="btn btn-theme enroll-btn">Enroll Now<i className="ti-angle-right"></i></a>
                        </div>
                        
                    </div>	
                    
                    <div className="edu_wraper border">
                        <h4 className="edu_title">Course Features</h4>
                        <ul className="edu_list right">
                            <li><i className="ti-user"></i>Student Enrolled:<strong>{this.state.sidebarData['student']}</strong></li>
                            <li><i className="ti-files"></i>lectures:<strong>{this.state.sidebarData['lectures']}</strong></li>
                            <li><i className="ti-game"></i>Quizzes:<strong>{this.state.sidebarData['quizzes']}</strong></li>
                            <li><i className="ti-time"></i>Duration:<strong>{this.state.sidebarData['duration']}</strong></li>
                            <li><i className="ti-tag"></i>Skill Level:<strong>{this.state.sidebarData['skill level']}</strong></li>
                            <li><i className="ti-flag-alt"></i>Language:<strong>{this.state.sidebarData['language']}</strong></li>
                            <li><i className="ti-shine"></i>Assessment:<strong>{this.state.sidebarData['assessment']}</strong></li>
                        </ul>
                    </div>
                    
                </Col>
        )
}
}
export default Sidebar 
