
import React,{ useState } from 'react' 
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback,
} from 'reactstrap'
import {TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText,Breadcrumb } from 'reactstrap';
import classnames from 'classnames';
import FAQGerneral from './FAQGerneral'
import FAQPayment from './FAQPayment'
import FAQUpgrade from './FAQUpgrade'
    
export default function FAQ (props){
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);}
       return(
        <div>
          {/* <!-- =================================== FAQS =================================== --> */}
          <section className="pt-0">
            <Container>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { toggle('1'); }}
                    >
                      Gerneral
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { toggle('2'); }}
                    >
                      Payment
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { toggle('3'); }}
                    >
                      UpGrade
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col lg='10' md='12' sm='12'>
                      <Card body>
                        <FAQGerneral />
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col lg='10' md='12' sm='12'>
                      <Card body>
                        <FAQPayment />
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col lg='10' md='12' sm='12'>
                      <Card body>
                        <FAQUpgrade />
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </Container>
          </section>
          {/* <!-- ====================================== FAQS =================================== --> */}
         </div>
        )
}