
import React, {useState} from 'react';
import {Container,Row, Col} from 'reactstrap'
import CardCarousel from './Carousel'
import {courseData} from './CourseData'
import  SingleCard from "./SingleCard";

class CourseCard extends React.Component {
  render(){
    return (
      <section className="py-5">
        <Container classname='position-relative'>
          <div className='sec-heading center'>
            <p>New &amp; Trending</p>
            <h2>
              <span className="theme-cl">Featured</span> Courses by professional Instructor
            </h2>
          </div>
          <CardCarousel />
        </Container>
      </section>
    );
  }
  
}

export default CourseCard;
