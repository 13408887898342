
import React from 'react' 
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback, Breadcrumb
} from 'reactstrap'
import './ContactUs.scss'

    
export default class ContactUs extends React.Component{
   // <!-- ============================ Page Title Start================================== -->
	render() {
		return (
			<div>
				<section className="bg-light">
					<Container>
						<Row>
							<Col lg='8' md='7'>
								<div className="prc_wrap">
									<div className="prc_wrap_header">
										<h4 className="property_block_title">Send Us a Message</h4>
									</div>
									<div className="prc_wrap-body">
										<div className="row">
											<Col lg='6' md='12'>
												<div className="form-group">
													<label>Name</label>
													<input type="text" className="form-control simple" />
												</div>
											</Col>
											<div className="col-lg-6 col-md-12">
												<div className="form-group">
													<label>Email</label>
													<input type="email" className="form-control simple" />
												</div>
											</div>
										</div>

										<div className="form-group">
											<label>Subject</label>
											<input type="text" className="form-control simple" />
										</div>

										<div className="form-group">
											<label>Message</label>
											<textarea className="form-control simple"></textarea>
										</div>

										<div className="form-group">
											<button className="btn btn-theme" type="submit">Submit Request</button>
										</div>
									</div>

								</div>

							</Col>

							<Col lg='4' md='5'>

								<div className="prc_wrap">

									<div className="prc_wrap_header">
										<h4 className="property_block_title">Reach Us</h4>
									</div>

									<div className="prc_wrap-body">
										<div className="contact-info">

											<h2>Get In Touch</h2>
											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do </p>

											<div className="cn-info-detail">
												<div className="cn-info-icon">
													<i className="ti-home"></i>
												</div>
												<div className="cn-info-content">
													<h4 className="cn-info-title">Reach Us</h4>
													<p>Kowloon, Hong Kong</p>
												</div>
											</div>

											<div className="cn-info-detail">
												<div className="cn-info-icon">
													<i className="ti-email"></i>
												</div>
												<div className="cn-info-content">
													<h4 className="cn-info-title">Drop A Mail</h4>
													<p>support@ecpd.com<br />info@ecpd.com</p>
												</div>
											</div>

											<div className="cn-info-detail">
												<div className="cn-info-icon">
													<i className="ti-mobile"></i>
												</div>
												<div className="cn-info-content">
													<h4 className="cn-info-title">Call Us</h4>
													<p>(852) 2123 4567<br />
                             (852) 2123 5678</p>
												</div>
											</div>

										</div>
									</div>
								</div>

							</Col>

						</Row>


					</Container>

				</section>
			</div>

		)
}}