import React, { useState } from 'react';
import {
      Row, Col, Nav, Container, 
      Dropdown, DropdownToggle, DropdownMenu, DropdownItem,UncontrolledDropdown,
      Card } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import './DashBoardLeft.scss'

class DashBoradLeft extends React.Component{
  constructor(props){
    super(props);

    this.setDropdownOpen = this.setDropdownOpen.bind(this)
  
  this.state = {
    dropdownOpen: false,

  } 
  }
  setDropdownOpen(){
    this.setState({
      dropdownOpen: !this.state.dropdownOpen.value
    });
  }
  render(){
    const toggle = () => this.setDropdownOpen();
    const dropdownOpen = this.state.dropdownOpen

      return(
        <Col lg='3' md='3' className='p-0'>
          <div className='dashboard-navbar'>
            <div className="d-user-avater">
              <img src="https://via.placeholder.com/500x500" className="img-fluid avater" alt="" />
              <h4>Adam Harshvardhan</h4>
              <span>VIP</span>
            </div>

            <div className="d-navigation">
              <ul id="side-menu">
                <li>
                  <a href="/myAccount"><FontAwesomeIcon icon={['far','user']} /> Dashboard</a>
                </li>
                <li>
                  <a href="/Profile"><FontAwesomeIcon icon={['far','heart']} /> My Profile</a>
                </li>
                <li>
                  <a href="/myAccount"><FontAwesomeIcon icon={['far','heart']} /> Saved Courses</a>
                </li>
                <div>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle className='dropdown'>
                      <a href="#" className='p-0 dropdown'>
                        <FontAwesomeIcon icon={['fas','layer-group']} /> All Courses <FontAwesomeIcon icon={['fas','angle-left']}/>
                      </a>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>All</DropdownItem>
                        <DropdownItem>Published</DropdownItem>
                        <DropdownItem>Pending</DropdownItem>
                        <DropdownItem>Expired</DropdownItem>
                        <DropdownItem>In Draft</DropdownItem>

                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </div>
                <li>
                  <a href="/myAccount"><FontAwesomeIcon icon={['fas','shopping-cart']}/> My Order</a>
                </li>
                <li>
                  <a href="/myAccount"><FontAwesomeIcon icon={faCog}/> Settings</a>
                </li>
                <li>
                  <a href="/myAccount"><FontAwesomeIcon icon={['fas','comment-alt']}/> Reviews</a>
                </li>
                <li>
                  <a href="index"><FontAwesomeIcon icon={['fas','power-off']}/> Log Out</a>
                </li>
              </ul>
            </div>
          </div>
        </Col>
    )
  }
}
              
export default DashBoradLeft;