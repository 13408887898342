import NavBar from "../components/NavBar";
import CourseSideBar from "../components/CourseSideBar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

function Courses() {
  return(
    <div>
      <NavBar />
      <CourseSideBar />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default Courses;
