
import React from 'react' 
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback, Breadcrumb
} from 'reactstrap'

    
export default class Privacy extends React.Component {
	// <!-- ============================ Page Title Start================================== -->
	render() {
		return (
			<div>
				{/* <!-- ============================ Privacy Start ================================== --> */}
				<section className="gray">
					<Container>

						{/* <!-- row Start --> */}
						<Row>

							<Col lg='8' md='12'>
								<div className="prc_wrap">

									<div className="prc_wrap_header">
										<h4 className="property_block_title">Our Terms & Conditions</h4>
									</div>

									<div className="prc_wrap-body">
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
										<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus</p>
										<p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.</p>
									</div>

								</div>

							</Col>


						</Row>
						{/* <!-- /row -->		 */}

					</Container>

				</section>
				{/* <!-- ============================ Privacy End ================================== --> */}
			</div>
		)
	}
}