import React, { useState } from 'react';
import {
      Row, Col, Nav, Container, 
      Button, Form, FormGroup, Label, Input, FormText,
      Card } from 'reactstrap'

import './CourseSideBar.scss'

class CourseSideBarLeft extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        searchCourse: {
          courseCategories : [
            'Backend','Frontend','General',
            'IT & Software','Photography','Technology'
          ],
          instructors: [
            'Keny White', 'Hinata Hyuga', 'Mike hussy', 
            'Adam Riky', 'Balcony', 'Icon'
          ],
          price: [
            'All', 'Free', 'Paid'
          ]
        },
        relatedProducts: [
          {
            title: 'The Source of Learning and Development',
            star: 4,
            price: 99,
            prePrice: 149,
            thumbnail: "https://via.placeholder.com/700x700" 
          },
          {
            title: 'Full Web Designing Course with 20% Offer',
            star: 5,
            price: 89,
            prePrice: 179,
            thumbnail: "https://via.placeholder.com/700x700" 
          },
          {
            title: 'The Source of Learning Advance WordPress',
            star: 4,
            price: 199,
            prePrice: 279,
            thumbnail: "https://via.placeholder.com/700x700" 
          },
          {
            title: 'Advance Magento & Drupal Development',
            star: 4,
            price: 599,
            prePrice: 999,
            thumbnail: "https://via.placeholder.com/700x700" 
          }
        ]
      }
    }
    render(){
        return(
          <Col lg='4'>
            <Card className='p-3'>
              <Form className="form-inline addons mb-3">
                <FormGroup>
                  <Input className="form-control" type="search" placeholder="Search Courses" aria-label="Search"></Input>
                  <Button className="btn my-2 my-sm-0" type="submit"><i className="ti-search"></i></Button>
                </FormGroup>
              </Form>
              <h4 className="side_title">Course categories</h4>
              <ul className='no-ul-list mb-3'>
                {
                  this.state.searchCourse.courseCategories.map((item,index) =>{
                  return(
                    <li>
                      <Input id ={'aa-'+index} className="checkbox-custom" name={'aa-'+index}  type="checkbox" />
                      <label for={'aa-'+index}  className="checkbox-custom-label">{item}</label>
                    </li>
                  )
                })}
              </ul>

              <h4 className="side_title">Instructors</h4>
              <ul className="no-ul-list mb-3">
              {
                  this.state.searchCourse.instructors.map((item,index) =>{
                  return(
                    <li>
                      <Input id ={'aa-'+index+10} className="checkbox-custom" name={'aa-'+index+10}  type="checkbox" />
                      <label for={'aa-'+index+10}  className="checkbox-custom-label">{item}</label>
                    </li>
                  )
                })}
              </ul>

              <h4 className="side_title">Price</h4>
              <ul class="no-ul-list mb-3">
              {
                  this.state.searchCourse.price.map((item,index) =>{
                  return(
                    <li>
                      <Input id ={'aa-'+index+20} className="checkbox-custom" name={'aa-'+index+20}  type="checkbox" />
                      <label for={'aa-'+index+20}  className="checkbox-custom-label">{item}</label>
                    </li>
                  )
                })}
              </ul>
            </Card>
                  
                
            <Card className='p-3'>
            <h4 className="side_title">Related Products</h4>
            <div className="related_items mb-4">
            {/* Single Related Items */}
            {
              this.state.relatedProducts.map((item,index)=>{
                return(
                  <div className="product_item">
                    <div className="thumbnail">
                      <a href="#"><img src={item.thumbnail} class="img-fluid" alt="" /></a>
                    </div>
                    <div className="info">
                      <h6 className="product-title"><a href="#">{item.title}</a></h6>
                      <div className="woo_rating">
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <span className="price"><p className="price_ver">${item.price}<del>${item.prePrice}</del></p></span>
                    </div>
                  </div>)})
              }
            </div>
            </Card>
            <Card>
            <h4 className="side_title">Popular Tags</h4>
              <div className="popular_tags">
                <div className="tag_cloud">
                  <a href="#" className="tag-cloud-lin">business</a>
                  <a href="#" className="tag-cloud-lin">design</a>
                  <a href="#" className="tag-cloud-lin">development</a>
                  <a href="#" className="tag-cloud-lin">php</a>
                  <a href="#" className="tag-cloud-lin">wordpress</a>
                  <a href="#" className="tag-cloud-lin">magento</a>
                  <a href="#" className="tag-cloud-lin">skills</a>
                  <a href="#" className="tag-cloud-lin">software</a>
                  <a href="#" className="tag-cloud-lin">accounting</a>
                </div>	
              </div>
            </Card>
          </Col>
      )
    }
}
              
export default CourseSideBarLeft;