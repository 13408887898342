import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Col, 
  Row,Container
} from 'reactstrap';
import SingleCard from './SingleCard'
import {courseData} from './CourseData'
import './Carousel.scss'

const CardCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(3);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === courseData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? courseData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = courseData.map((item,index) => {
    if(index === 0){
      return(
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
        >
          <Row>
              <SingleCard {...courseData[courseData.length-1]} />
              <SingleCard {...courseData[index]} />
              <SingleCard {...courseData[index+1]} />
          </Row>
        </CarouselItem>
      );
    }else if(index === courseData.length-1){
      return(
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
        >
          <Row>
              <SingleCard {...courseData[courseData.length-2]} />
              <SingleCard {...courseData[index]} />
              <SingleCard {...courseData[0]} />
          </Row>
        </CarouselItem>
      );
  }else{
    return(
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <Row>
        <SingleCard {...courseData[index-1]} />
        <SingleCard {...courseData[index]} />
        <SingleCard {...courseData[index+1]} />
        </Row>
      </CarouselItem>
      )
    };
  }
  )
  
  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={8000}
      className="course-card-carousel"
    >
      <CarouselIndicators items={courseData} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl className='classCarousel' direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl className='float-right' direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default CardCarousel;