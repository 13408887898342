import React from 'react';
import {Container, Card, Row, Col} from 'reactstrap'


class SingleCategory extends React.Component{
    constructor(props){
			super(props);
		this.state = {
			singleCategory: [
				{
					categoryTitle : 'Development',
					numOfClasses: 23,
					categoryPic: "https://via.placeholder.com/70x70"
				},
			]
			
		}
    }
    render(){
        return(
					<Col lg='4' md='4'sm='6'>
						<div className="edu_cat_2 cat-1">
						<div className="edu_cat_icons">
								<a className="pic-main" href="#">
									<img src={this.state.singleCategory[0].categoryPic} className="img-fluid" alt="" />
								</a>
						</div>
						<div className="edu_cat_data">
								<h4 className="title">
								<a href="/Courses">{this.state.singleCategory[0].categoryTitle}</a>
								</h4>
								<ul className="meta">
									<li className="video"><i className="ti-video-clapper"></i>{this.state.singleCategory[0].numOfClasses} Classes</li>
								</ul>
						</div>
						</div>			
					</Col>

        )
    }
}
export default SingleCategory;