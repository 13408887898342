import  React  from "react";
import {courseDetailData } from './CourseDetailData'
import '../assets/css/plugins/themify.css'

export default class CourseOverview extends React.Component{
    constructor(props){
        super(props)
        this.state={
            courseDetailData:courseDetailData['Ruby on Rails Program']
        }
    }
    render(){
        return(
            
            <div className="edu_wraper border">
                <h4 className="edu_title">Course Overview</h4>
                {this.state.courseDetailData['Course Overview'].map((item,index)=>{
                        return(<p>{this.state.courseDetailData['Course Overview'][index]}</p>)
                    })}
                <p>{this.state.courseDetailData['Course Overview1']}</p>		
                <p>{this.state.courseDetailData['Course Overview2']}</p>
                <h6>Requirements</h6>
                <ul className="lists-3">
                    {this.state.courseDetailData['Requirements'].map((item,index)=>{
                        return(<li>{this.state.courseDetailData['Requirements'][index]}</li>)
                    })}
                                   </ul>
            </div>
        )   
    }
}