import React from 'react'
import { instructorData} from './CourseDetailData'

export default class Instructor extends React.Component{
	constructor(props){
        super(props)
        this.state={
            instructorData:instructorData['Ruby on Rails Program']
        }
    }
    render(){
        return(
        <div className="single_instructor border">
							<div className="single_instructor_thumb">
								<a href="#"><img src={this.state.instructorData['img']} className="img-fluid" alt=""/></a>
							</div>
							<div className="single_instructor_caption">
								<h4><a href="#">{this.state.instructorData['name']}</a></h4>
								<ul className="instructor_info">
									<li><i className="ti-video-camera"></i>{this.state.instructorData['video-total']}</li>
									<li><i className="ti-control-forward"></i>{this.state.instructorData['course-total']}</li>
									<li><i className="ti-user"></i>{this.state.instructorData['exp.']}</li>
								</ul>
								<p>{this.state.instructorData['detail']}</p>
								<ul className="social_info">
									<li><a href="#"><i className="ti-facebook"></i></a></li>
									<li><a href="#"><i className="ti-twitter"></i></a></li>
									<li><a href="#"><i className="ti-linkedin"></i></a></li>
									<li><a href="#"><i className="ti-instagram"></i></a></li>
								</ul>
							</div>
						</div>)
    }
}