import React from "react";
import logoIcon from "./../assets/img/ecpd.png";
import { 
  Container, Collapse, Button, Col, Row,
  Nav, Navbar, NavItem, NavLink, NavbarToggler, NavbarBrand,
  UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, 
  ModalHeader, ModalBody, Form, FormGroup, Label, Input, Modal
} from "reactstrap";

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarIsOpen: false,
      logInModalIsOpen: false,
      signUpModalIsOpen: false
    }

    this.navbarToggle = this.navbarToggle.bind(this)
    this.logInModalIsOpen = this.logInModal.bind(this)
    this.signUpModalIsOpen = this.signUpModal(this)

  }

  navbarToggle() {
    this.setState({
      navbarIsOpen: !this.state.navbarIsOpen
    })
  }

  logInModal() {
    this.setState({
      logInModalIsOpen: !this.state.logInModalIsOpen
    })
  }

  signUpModal() {
    this.setState({
      signUpModalIsOpen: !this.state.signUpModalIsOpen
    })
  }

  render() {
    return(
      <div className="bg-white shadow fixed-top">
        <Navbar expand='md' color="white" light>
          <NavbarBrand href='/'>
            <img src={logoIcon} width='120' />
          </NavbarBrand>
          <NavbarToggler 
            onClick={()=>(
              this.navbarToggle()
            )}
          >
          </NavbarToggler>
          <Collapse isOpen={this.state.navbarIsOpen} navbar>
            <Nav navbar>
              {/* Home */}
              <NavItem>
                <NavLink href="/">
                  Home
                </NavLink>
              </NavItem>
              {/* End of Home */}
              
              {/* About Us */}
              <NavItem>
                <NavLink href="/AboutUs">
                  About Us
                </NavLink>
              </NavItem>
              {/* End of About Us */}


              {/* Courses */}
              <NavItem>
                <NavLink href="/Courses">
                  Courses
                </NavLink>
              </NavItem>
              {/* End of Courses */}

              {/* Register */}
              <NavItem>
                <NavLink href="/Register">
                  Register
                </NavLink>
              </NavItem>
              {/* End of Register */}

              {/* DashBoard */}
              <NavItem>
                <NavLink href="/myAccount">
                  My Account
                </NavLink>
              </NavItem>
              {/* End of DashBoard */}

              {/* Add To Cart */}
              <NavItem>
                <NavLink href="/AddToCart">
                  Add To Cart
                </NavLink>
              </NavItem>
              {/* End of Add To Cart */}

              {/* Contact */}
              <NavItem>
                <NavLink href="/Contact">
                  Contact Us
                </NavLink>
              </NavItem>
              {/* End of Contact */}


              {/* Pages */}
              {/* <NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Pages
                  </DropdownToggle>
                  <DropdownMenu> */}

                    {/* User Dashboard */}
                    {/* <UncontrolledDropdown direction="right">
                      <DropdownToggle nav caret>
                        User Dashboard
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>
                          <NavLink href="/UserDashboard">
                            User Dashboard
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/MyProfile">
                            My Profile
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/MyCourses">
                            My Courses
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/MyOrder">
                            My Order
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/SavedCourses">
                            Saved Courses
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/MyReviews">
                            My Reviews
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/MySettings">
                            My Settings
                          </NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <DropdownItem divider /> */}
                    {/* End of User Dashboard */}

                    {/* Shop Pages */}
                    {/* <UncontrolledDropdown direction="right">
                      <DropdownToggle nav caret>
                        Shop Pages
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>
                          <NavLink href="/ShopFullWidth">
                            Shop Full Width
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/ShopSidebarLeft">
                            Shop Sidebar Left
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/ShopSidebarRight">
                            Shop Sidebar Right
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/ShopDetail">
                            Shop Detail
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/AddToCart">
                            Add To Cart
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/Wishlist">
                            Wishlist
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/Checkout">
                            Checkout
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <NavLink href="/Order">
                            Order
                          </NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <DropdownItem divider /> */}
                    {/* End of Shop Pages */}
{/* 
                    <DropdownItem>
                      <NavLink href="/AboutUs">
                        About Us
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink href="/BlogStyle">
                        Blog Style
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink href="/BlogDetail">
                        Blog Detail
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink href="/Pricing">
                        Pricing
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink href="/404Page">
                        404 Page
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink href="/Register">
                        Register
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink href="/Elements">
                        Elements
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink href="/PrivacyPolicy">
                        Privacy Policy
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink href="/FAQs">
                        FAQs
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem> */}
              {/* End of Pages */}


            </Nav>

            <Nav className='ml-auto d-none d-lg-block' navbar>
              {/* Sign In button */}
              <Button 
                color='danger' 
                outline 
                onClick={()=>(this.logInModal())}
                style={{borderColor: "pink", fontWeight: "bold"}}
              >
                Sign in
              </Button>
              
              {/* Log In modal */}
              <Modal 
                isOpen={this.state.logInModalIsOpen} 
                toggle={()=>(this.logInModal())}
              >
                <ModalHeader 
                  tag='h1' 
                  toggle={()=>(this.logInModal())}
                >
                  Log In
                </ModalHeader>
                <ModalBody>
                  <Form className='login-form'>
                    <FormGroup>
                      <Label for='userName'>User Name</Label>
                      <Input id='userName' type='text' placeholder='Username' />
                    </FormGroup>
                    <FormGroup>
                      <Label for='password'>Password</Label>
                      <Input id='password' type='password' placeholder='*******' />
                    </FormGroup>

                    {/* Todo: redirect to my-account page if login successfully; tentatively use <a> link to my-account page */}
                    {/* <Button block color='danger'>Login</Button> */}
                    <a href="/myAccount" className="btn btn-danger btn-block">Login</a>


                    <Row form className="row align-items-center">
                      <Col xs='6'>
                        <FormGroup check>
                          <Label check>
                            <Input type='checkbox' />
                            Save Password
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs='6' className='text-right'>
                        <Button color='link' style={{color: 'red'}}>
                          Forget Password?
                        </Button>
                      </Col>
                    </Row>
                  </Form>

                  <p className='mt-2 text-center'>
                    Haven't Any Account?
                    {"  "}<a href='/register' className='link'>Click here</a>
                  </p>
                </ModalBody>
              </Modal>
              {/* End of Log In modal */}
              {/* End of Sign In button */}


              {/* / Sign Up button */}
              <Button 
                onClick={()=>(this.signUpModal())}
                className='ml-2'
                color='black'
                style={{fontWeight: "bold"}}

              >
                Sign up
              </Button>
              {/* Sign Up modal */}
              <Modal 
                isOpen={this.state.signUpModalIsOpen} 
                toggle={()=>(this.signUpModal())}
              >
                <ModalHeader 
                  tag='h1' 
                  toggle={()=>(this.signUpModal())}
                >
                  Sign Up
                </ModalHeader>
                <ModalBody>
                  <Form className='login-form'>
                    <FormGroup>
                      <Input type='text' placeholder='Full Name' />
                    </FormGroup>
                    <FormGroup>
                      <Input type='email' placeholder='Email' />
                    </FormGroup>
                    <FormGroup>
                      <Input type='text' placeholder='Username' />
                    </FormGroup>
                    <FormGroup>
                      <Input type='password' placeholder='*******' />
                    </FormGroup>
                    <Button block color='danger'>Sign Up</Button>
                  </Form>

                  <p className='mt-2 text-center'>
                    Already Have An Account?
                    {"  "}<a href='/login' className='link'>Go For LogIn</a>
                  </p>
                </ModalBody>
              </Modal>
              {/* End of Sign Up modal */}
              {/* / End of Sign Up button  */}

            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

export default NavBar;
