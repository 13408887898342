import React from 'react'
import {Row} from 'reactstrap'
import DashBoradLeft from './DashBoardLeft'
import DashBoradRight from './DashBoardRight'
import './DashBoard.scss'

export default class DashBoard extends React.Component{
	render(){
		return(
			<div className="wrapper">
				<Row className='gray'>
					<DashBoradLeft />
					<DashBoradRight />
				</Row>
			</div>
		)
	}
}

