export  const testimonialData= [
    {   "ReviewerImage":"https://via.placeholder.com/500x500",
        "testimonial-title":"Adam Alloriam",
        "post":"Web Developer",
        "description":" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi facilis itaque minus non odio, quaerat ullam eligendi facilis itaque minus non odio, quaerat ullam unde  unde voluptatum Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi. "
    },
    {   "ReviewerImage":"https://via.placeholder.com/500x500",
        "testimonial-title":"Illa Millia",
        "post":"Project Manager",
        "description":" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi facilis itaque minus non odio, quaerat ullam eligendi facilis itaque minus non odio, quaerat ullam unde  unde voluptatum Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi. "
    },
    {   "ReviewerImage":"https://via.placeholder.com/500x500",
        "testimonial-title":"Rout Millance",
        "post":"Web Designer",
        "description":" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi facilis itaque minus non odio, quaerat ullam eligendi facilis itaque minus non odio, quaerat ullam unde  unde voluptatum Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi. "
    },
    {   "ReviewerImage":"https://via.placeholder.com/500x500",
        "testimonial-title":"williamson",
        "post":"Web Developer",
        "description":" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi facilis itaque minus non odio, quaerat ullam eligendi facilis itaque minus non odio, quaerat ullam unde  unde voluptatum Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi. "
    },
]