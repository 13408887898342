import React, { useState } from 'react';
import {
      Row, Col, Nav, Container,
      Breadcrumb, BreadcrumbItem,
      Card,CardImg, CardText, CardBody,CardTitle, CardSubtitle,
      Table
    } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import './DashBoardRight.scss'

class Subscription extends React.Component{
  constructor(props){
    super(props)
  }
  render(){
    return(
      <Col lg='6' md='6' sm='12'>
        <div className="course_overlay_cat">
          <div className="course_overlay_cat_thumb">
            <a href="#">
              <img src="https://via.placeholder.com/700x500" className="img-fluid" alt="" />
            </a>
          </div>
          <div className="course_overlay_cat_caption">
            <div className="llp-left">
              <h4><a href="#">{this.props.topic}</a></h4>
              <span>{this.props.classes} Classes</span>
            </div>
          </div>
        </div>
      </Col>
    )
  }
}

class NotificationItem extends React.Component{
  render(){
    return(
      <div className="ground ground-list-single">
        <a href="#">
          <div className="btn-circle-40 btn-success"><i className="ti-calendar"></i></div>
        </a>
        <div className="ground-content">
          <h6><a href="#">{this.props.sender}</a></h6>
          <small className="text-fade">{this.props.msg}</small>
          <span className="small">{this.props.time}</span>
        </div>
      </div>
    )
  }
}
class Notification extends React.Component{
  render(){
    return(
      <Col lg='4' md='12' sm='12'>
        <Card>
        <CardTitle className='card-header' tag='h6'>Notifications</CardTitle>
          <div className="ground-list ground-hover-list">
            <NotificationItem sender='Maryam Amiri' msg='Check New Admin Dashboard..' time='Just Now'/>
            <NotificationItem sender='Maryam Amiri' msg='You can Customize..' time='02 Min Ago'/>
            <NotificationItem sender='Maryam Amiri' msg='Need Responsive Business Tem...' time='10 Min Ago'/>
            <NotificationItem sender='Maryam Amiri' msg='Next Meeting on Tuesday..' time='15 Min Ago'/>

											
          </div>
        </Card>
      </Col>
    )
  }
}
class Orders extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    let status = this.props.status;
      if(status ==='inprogress'){
        status = <span className='payment_status inprogress'>In Progress</span>
      }else if(status ==='complete'){
        status = <span className='payment_status complete'>Completed</span>
      }else if(status ==='pending'){
        status = <span className='payment_status pending'>Pending Payment</span>
      }
    return(
      <tr>
        <th scope="row">{this.props.order}</th>
        <td>{this.props.date}</td>
        <td>{status}</td>
        <td>${this.props.total}</td>
        <td>
          <div className="dash_action_link">
            <a href="#" className="view">View</a>
            <a href="#" className="cancel">Cancel</a>
          </div>	
        </td>
      </tr>
    )
  }
}
class RecentOrder extends React.Component{
  render(){
    return(
      <Row>
        <Col  lg='12' md='12' sm='12'>
          <Card className='dashboard_container p-0'>
            {/* <div className="dashboard_container_header"> */}
              <CardTitle tag='h5' className="dashboard_fl_1 p-3">Recent Order
              </CardTitle>
              <CardBody className='dashboard_container_body'>
                <div className='table-responsive'>
                  <Table hover>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Order</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Total</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <Orders order='#0000149' total='110' date='2 July 2020' status='inprogress'/>
                      <Orders order='#0000179' total='150' date='25 July 2020' status='pending'/>
                      <Orders order='#0000249' total='110' date='2 August 2020' status='complete'/>
                    </tbody>
                  </Table>
                </div>
                
              </CardBody>
            {/* </div> */}
          </Card>
        </Col>
      </Row>
    )
  }
}

class DashBoradRight extends React.Component{ 
  render(){
      return(
        <Col lg='9' md='12' sm='12' className='pt-4 pb-4'>
            <Breadcrumb className='breadcrumb justify-content-start p-0'>
              <BreadcrumbItem className='breadcrumb-item'><a href='/'>Home</a></BreadcrumbItem>
              <BreadcrumbItem className='breadcrumb-item' active>Dashboard</BreadcrumbItem>
            </Breadcrumb>
            <Row className='mt-5'>
              <Col lg='4' md='6' sm='12'>
                <div className="dashboard_stats_wrap widget-1">
                  <div className="dashboard_stats_wrap_content"><h4>607</h4> <span>Listings Included</span></div>
                  <div className="dashboard_stats_wrap-icon"><i className="ti-location-pin"></i></div>
                </div>
              </Col>
              <Col lg='4' md='6' sm='12'>
                <div className="dashboard_stats_wrap widget-2">
                  <div className="dashboard_stats_wrap_content"><h4>102</h4> <span>Listings Remaining</span></div>
                  <div className="dashboard_stats_wrap-icon"><i className="ti-pie-chart"></i></div>
                </div>	
              </Col>
              <Col lg='4' md='6' sm='12'>
                <div className="dashboard_stats_wrap widget-4">
                  <div className="dashboard_stats_wrap_content"><h4>70</h4> <span>Featured Included</span></div>
                  <div className="dashboard_stats_wrap-icon"><i className="ti-user"></i></div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg='8' md='12' sm='12' className='subsriptions'>
                <Row>
                  <Subscription topic='Web Designing' classes='17'/>
                  <Subscription topic='Digital Marketing' classes='20'/>
                  <Subscription topic='Account & Chart' classes='22'/>
                  <Subscription topic='Business Development' classes='10'/>

                </Row>
              </Col>
              <Notification />
            </Row>
            <RecentOrder />
        </Col>
                
      )
  }
}
              
export default DashBoradRight;