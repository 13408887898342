import logo from '../logo.svg';
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback,
} from 'reactstrap'
import React from 'react'

// <!-- ============================ Footer Start ================================== -->
class Footer extends React.Component{
	render(){
		return(
      <footer className="dark-footer skin-dark-footer">
				<div>
					<Container> 
						<Row>
							<Col lg='3' md='3'>
								<div className="footer-widget">
									<img src="assets/img/logo-light.png" className="img-footer" alt="" />
									<div className="footer-add">
										<p>Kowloon, Hong Kong</p>
										<p>+852 2123 4568</p>
										<p>info@ecpd.com</p>
									</div>
								</div>
							</Col>		

							<Col lg='2' md='3'>
								<div className="footer-widget">
									<h4 className="widget-title">Navigations</h4>
									<ul className="footer-menu">
										<li><a href="AboutUs">About Us</a></li>
										<li><a href="Courses">Courses</a></li>
										<li><a href="AddToCart">Checkout</a></li>
										<li><a href="Contact">Contact</a></li>
										{/* <li><a href="blog.html">Blog</a></li> */}
									</ul>
								</div>
							</Col>
									
							<Col lg='2' md='3'>
								<div className="footer-widget">
									<h4 className="widget-title">New Categories</h4>
									<ul className="footer-menu">
										<li><a href="Courses">Designing</a></li>
										<li><a href="Courses">Nusiness</a></li>
										<li><a href="Courses">Software</a></li>
										<li><a href="Courses">WordPress</a></li>
										<li><a href="Courses">PHP</a></li>
									</ul>
								</div>
							</Col>
							
							<Col lg='2' md='3'>
								<div className="footer-widget">
									<h4 className="widget-title">Help & Support</h4>
									<ul className="footer-menu">
										<li><a href="#">Documentation</a></li>
										<li><a href="#">Live Chat</a></li>
										<li><a href="#">Mail Us</a></li>
										<li><a href="/Privacy">Privacy</a></li>
										<li><a href="FAQ">FAQ</a></li>
									</ul>
								</div>
							</Col>
							
							<Col lg='3' md='12'>
								<div className="footer-widget">
									<h4 className="widget-title">Download Apps</h4>
									<a href="#" className="other-store-link">
										<div className="other-store-app">
											<div className="os-app-icon">
												<i className="lni-playstore theme-cl"></i>
											</div>
											<div className="os-app-caps">
												Google Play
												<span>Get It Now</span>
											</div>
										</div>
									</a>
									<a href="#" className="other-store-link">
										<div className="other-store-app">
											<div className="os-app-icon">
												<i className="lni-apple theme-cl"></i>
											</div>
											<div className="os-app-caps">
												App Store
												<span>Now it Available</span>
											</div>
										</div>
									</a>
								</div>
							</Col>
							
						</Row>
					</Container>
				</div>
				
				<div className="footer-bottom">
					<Container>
						<Row align-items-center>
							
							<Col lg='6' md='6'>
							{/* <p className="mb-0"> */}
								<p mb='0'>© 2021 eCPD. Designd By <a href="#">eCPD</a>.</p>
							</Col>
							
							<Col lg='6' md='6' text-right>
								<ul className="footer-bottom-social">
									<li><a href="#"><i className="ti-facebook"></i></a></li>
									<li><a href="#"><i className="ti-twitter"></i></a></li>
									<li><a href="#"><i className="ti-instagram"></i></a></li>
									<li><a href="#"><i className="ti-linkedin"></i></a></li>
								</ul>
							</Col>
							
						</Row>
					</Container>
				</div>
			</footer>
		)
	}
}
			
// <!-- ============================ Footer End ================================== -->



export default Footer;