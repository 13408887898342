import NavBar from "../components/NavBar";
import SectionHeader from "../components/SectionHeader";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import CheckoutForm from "../components/CheckoutForm";

function Checkout() {
  return(
    <div>
      <NavBar />
      <SectionHeader
        titleName='Checkout'
        itemName='Checkout' 
      />
      <CheckoutForm />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default Checkout;
