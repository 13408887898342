
import React from 'react';
import {Container, Card, Row, Col} from 'reactstrap'
import SingleCategory from './SingleCategory'

class CourseCategory extends React.Component {
  render(){
    return (
      <section className='bg-light'>
        <Container>
          <div className="sec-heading center">
            <p>Popular Category</p>
            <h2><span className="theme-cl">Hot & Popular</span> Category For Learn</h2>
          </div>
          <Row>
            <SingleCategory />
            <SingleCategory />
            <SingleCategory />
            <SingleCategory />
            <SingleCategory />
            <SingleCategory />
            <SingleCategory />
            <SingleCategory />
            <SingleCategory />
          </Row>
        </Container>
      </section>
    );
  }
  
}

export default CourseCategory;
