import React from "react";
import { 
  Card, CardBody, CardTitle, Col, Container, 
  Form, FormGroup, Label, Row, Button, Input
} from "reactstrap";

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div>
        {/* Cards section */}
        <section className='pt-0'>
          <Container>
            <Row>
              {/* Sign Up (left) card */}
              <Col lg='6' md='6' sm='12'>
                <Card>
                  <CardBody>
                    <CardTitle tag='h1' className='text-center mb-4'>
                      Sign Up
                    </CardTitle>
                    <Form>
                      <Row form>
                        <Col lg='6' sm='12' md='6'>
                          <FormGroup>
                            <Label for='firstName'>
                              First Name
                            </Label>
                            <Input type='text' id='firstName' color='red' />
                          </FormGroup>
                        </Col>
                        <Col lg='6' sm='12' md='6'>
                          <FormGroup>
                            <Label for='email'>
                              Email
                            </Label>
                            <Input type='email' id='email' />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for='signUpUserName'>
                          Username
                        </Label>
                        <Input type='text' id='signUpUserName' />
                      </FormGroup>
                      <FormGroup>
                        <Label for='signUpPassword'>
                          Password
                        </Label>
                        <Input type='password' id='signUpPassword' />
                      </FormGroup>
                      <Button block color='danger' size='lg'>
                        Sign Up
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              {/* End of Sign Up (left) card */}
              
              {/* Sign In (right) card */}
              <Col lg='6' md='6' sm='12'>
                <Card>
                  <CardBody>
                    <CardTitle tag='h1' className='text-center mb-4'>
                      Sign In
                    </CardTitle>
                    <Form>
                      <FormGroup>
                        <Label for='signInUserName'>
                          User Name
                        </Label>
                        <Input type='text' id='signInUserName'/>
                      </FormGroup>
                      <FormGroup>
                        <Label id='signInPassword'>
                          Password
                        </Label>
                        <Input type='password' id='signInPassword' />
                      </FormGroup>
                      <Row form className="row align-items-center mb-5">
                        <Col xs='6'>
                          <FormGroup check>
                            <Label check>
                              <Input type='checkbox' />
                              Save Password
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs='6' className='text-right'>
                          <Button color='link' style={{color: 'red'}}>
                            Forget Password?
                          </Button>
                        </Col>
                      </Row>
                      <a href="/myAccount" className="btn btn-danger btn-block btn-lg">Login</a>
                      {/* todo: redirect to myAccount if login successful, link to myAccount tentatively */}
                      {/* <Button block color='danger' size='lg'>Login</Button> */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              {/* End of Sign In (right) card */}
            </Row>
          </Container>
        </section>
        {/* End of Cards section */}

      </div>
    )
  }
}

export default CreateAccount;
