import React from 'react'
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback,
} from 'reactstrap'
import {courseReviewData} from './CourseDetailData'




const CourseReview =(props)=>{
 

        return(
            // {/* <!-- Reviews --> */}
           
                    //   <!-- reviews-comments-item --> 
                        <div className="reviews-comments-item">
                            <div className="review-comments-avatar">
                                <img src={props.ReviewImage} className="img-fluid" alt=""/> 
                            </div>
                            <div className="reviews-comments-item-text">
                                
                                        <h4><a href="#">{props.name}</a><span className="reviews-comments-item-date"><i className="ti-calendar theme-cl"></i>{props.date}</span></h4>  
                              
                                <div className="listing-rating high" data-starrating2="5"><i className="ti-star active"></i><i className="ti-star active"></i><i className="ti-star active"></i><i className="ti-star active"></i><i className="ti-star active"></i><span className="review-count">{props['review-count']}</span> </div>
                                <div className="clearfix"></div>
                                <p>{props.review}</p>
                                <div className="pull-left reviews-reaction">
                                    <a href="#" className="comment-like active"><i className="ti-thumb-up"></i> {props['ti-thumb-up']}</a>
                                    <a href="#" className="comment-dislike active"><i className="ti-thumb-down"></i> {props['ti-thumb-down']}</a>
                                    <a href="#" className="comment-love active"><i className="ti-heart"></i> {props['ti-heart']}</a>
                                </div>
                            </div>
                        </div>
                    //   <!--reviews-comments-item end-->  

        )
    }
class CourseReviewShow extends React.Component{

    render(){
     
        return(
            <div>
                
                <div className="list-single-main-item fl-wrap border">
                <div className="list-single-main-item-title fl-wrap">
                    <h3>Item Reviews -  <span> {courseReviewData.length} </span></h3>
                </div>
                    <div className="reviews-comments-wrap">
                        {/* <!-- reviews-comments-item -->   */}
                        <div>{
            courseReviewData.map((item)=>{
                return(
                    <CourseReview name={item['name']} ReviewImage={item['ReviewImage']} date={item['date']} review={item['review']} review-count={item['review-count']} ti-thumb-up={item['ti-thumb-up']} ti-thumb-down={item['ti-thumb-down']} ti-heart={item['ti-heart']}/>
                )
            })
            }</div>
                        {/* <!--reviews-comments-item end-->   */}

                    </div>
                </div>
            </div>
          
        )
    }
}
export default CourseReviewShow