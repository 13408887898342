import React from 'react'
import {
    Button, Container, Row, Col,
    Form, FormGroup, Label, Input, Fonttext, FontFeedback,
} from 'reactstrap'
import CourseHeader from './CourseHeader'
import  CourseOverview  from "./CourseOverview";
// import CourseCircullum from './CourseCircullum'
import  CourseRating  from "./CourseRating";
import  Instructor  from './Instructor'
import CourseReviewShow from './CourseReview'
import SubmitReview from './SubmitReview'
import Sidebar from './Sidebar'
import CourseCircullum2 from './CourseCircullum2'




export default class CourseDetail extends React.Component{
	render(){
				return(
			<div>
				<CourseHeader />
					<Container>
							<Row>
									<Col lg='8' md='8'>
										
										< CourseOverview />
										
										{/* <CourseCircullum /> */}
										<CourseCircullum2 />
								
										<CourseRating />
										
										<Instructor />
									
										<CourseReviewShow />
							
										<SubmitReview />	
									</Col>
								
								<Sidebar />
							
							</Row>
					</Container>
			</div>
						)
				}
}

