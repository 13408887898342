import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import CourseDetails from "./pages/CourseDetail";
import Register from "./pages/Register";
import AboutUs from "./pages/AboutUs";
import Checkout from "./pages/Checkout";
import AddToCart from "./pages/AddToCart";
import MyAccount from "./pages/MyAccount";
import Profile from './components/Profile'
import PrivacyPage from './pages/PrivacyPage'
import './assets/css/plugins/themify.css'
import Contact from "./pages/Contact";
import FAQsPage from "./pages/FAQsPage";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/FAQ">
            <FAQsPage />
          </Route>
          <Route path="/Privacy">
            <PrivacyPage />
          </Route>
          <Route path="/AddToCart">
            <AddToCart />
          </Route>
          <Route path="/Checkout">
            <Checkout />
          </Route>
          <Route path="/Register">
            <Register />
          </Route>
          <Route path="/AboutUs">
            <AboutUs />
          </Route>
          <Route path="/Courses">
            <Courses />
          </Route>
          <Route path="/CourseDetail">
            <CourseDetails />
          </Route>
          <Route path="/myAccount">
            <MyAccount />
          </Route>
          <Route path="/Profile">
            <Profile />
          </Route>
          <Route path="/Contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
