import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Col, 
  Row
} from 'reactstrap';
import {Testimonial,TestimonialShare} from './Testimonial'
import {testimonialData} from './TestimonialData'
import './TestiCarousel.scss'

const TestiCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(3);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === testimonialData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? testimonialData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = testimonialData.map((item,index) => {
    if(index === 0){
      return(
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
        >
          <Row>
              {/* <TestimonialShare {...testimonialData[testimonialData.length-1]} /> */}
              <TestimonialShare {...testimonialData[index]} />
              {/* <TestimonialShare {...testimonialData[index+1]} /> */}
          </Row>
        </CarouselItem>
      );
    }else if(index === testimonialData.length-1){
      return(
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
        >
          <Row>
              {/* <TestimonialShare {...testimonialData[testimonialData.length-2]} /> */}
              <TestimonialShare {...testimonialData[index]} />
              {/* <TestimonialShare {...testimonialData[0]} /> */}
          </Row>
        </CarouselItem>
      );
  }else{
    return(
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <Row>
        {/* <TestimonialShare {...testimonialData[index-1]} /> */}
        <TestimonialShare {...testimonialData[index]} />
        {/* <TestimonialShare {...testimonialData[index+1]} /> */}
        </Row>
      </CarouselItem>
      )
    };
  }
  )
  
  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={8000}
    >
      {/* <CarouselIndicators items={testimonialData} activeIndex={activeIndex} onClickHandler={goToIndex} /> */}
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default TestiCarousel;