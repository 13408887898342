import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import Feature from "../components/Feature"
import CourseCard from "../components/CourseCard"
import CourseCategory from "../components/CourseCategory"
import Newsletter from "../components/Newsletter"
import Footer from "../components/Footer"
import Testimonial from "../components/Testimonial";

function Home() {
  return(
    <div>
      <NavBar />
      <Hero />
      <CourseCard />
      <CourseCategory />
      <Feature />
      <Testimonial />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default Home;
