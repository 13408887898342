import NavBar from "../components/NavBar";
import SectionHeader from "../components/SectionHeader";
import Privacy from "../components/Privacy";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

function PrivacyPage () {
  return(
    <div>
      <NavBar />
      <SectionHeader 
        titleName='Privacy & Policy' 
        itemName='Privacy'
      />
      <Privacy />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default PrivacyPage;
