import React from 'react'
import {Row} from 'reactstrap'
import DashBoradLeft from './DashBoardLeft'
import ProfileRight from './ProfileRight'
import './DashBoard.scss'

export default class DashBoard extends React.Component{
	render(){
		return(
			<Row className='gray'>
				<DashBoradLeft />
				<ProfileRight />
			</Row>
		)
	}
}

