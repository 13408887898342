export const courseData = [
    {
      'courseDetail': 'Tableau For Beginners: Get CA Certified Grow Your Career',
      'courseImage': 'https://via.placeholder.com/700x500',
      'courseFacts':['Expert','Professional','Design'],
      'viewCounts': 184684,
      'coursePrice': 69.77,
      'courseDuration': '3h 30min',
      'instructor': 'Shaurya Preet',
      'instructorImage': 'https://via.placeholder.com/500x500',
      'courseBlockTime': 1
    },
    {
      'courseDetail': 'The Complete Financial Analyst Course 2020',
      'courseImage': 'https://via.placeholder.com/700x500',
      'courseFacts':['Expert','Professional','Design'],
      'viewCounts': 742854,
      'coursePrice': 79.99,
      'courseDuration': '6h 30min',
      'instructor': 'Litha Maildick',
      'instructorImage': 'https://via.placeholder.com/500x500',
      'courseBlockTime': 1
    },
    {
      'courseDetail': 'PMP Exam Prep Seminar - PMBOK Guide 6',
      'courseImage': 'https://via.placeholder.com/700x500',
      'courseFacts':['Expert','Professional','Design'],
      'viewCounts': 532984,
      'coursePrice': 149.7,
      'courseDuration': '5h 50min',
      'instructor': 'Chrish Gyle',
      'instructorImage': 'https://via.placeholder.com/500x500',
      'courseBlockTime': 10
    },
    {
      'courseDetail': 'The Complete Business Plan Course (Includes 50 Templates)',
      'courseImage': 'https://via.placeholder.com/700x500',
      'courseFacts':['Expert','Professional','Design'],
      'viewCounts': 656684,
      'coursePrice': 620,
      'courseDuration': '3h 40min',
      'instructor': 'Anoop Ojha',
      'instructorImage': 'https://via.placeholder.com/500x500',
      'courseBlockTime': 2
    },
    {
      'courseDetail': 'Tableau 2020 A-Z:Hands-On Tableau Training For Data Science!',
      'courseImage': 'https://via.placeholder.com/700x500',
      'courseFacts':['Expert','Professional','Design'],
      'viewCounts': 532984,
      'coursePrice': 99.7,
      'courseDuration': '8h 50min',
      'instructor': 'Shilpa Shekh',
      'instructorImage': 'https://via.placeholder.com/500x500',
      'courseBlockTime': 4
    },
    {
      'courseDetail': 'Learn How to use react to create website',
      'courseImage': 'https://via.placeholder.com/700x500',
      'courseFacts':['Expert','Professional','Design'],
      'viewCounts': 99999,
      'coursePrice': 9.7,
      'courseDuration': '18h 50min',
      'instructor': 'Gyle Chrish',
      'instructorImage': 'https://via.placeholder.com/500x500',
      'courseBlockTime': 2
    },
  ]
  