import NavBar from "../components/NavBar";
import SectionHeader from "../components/SectionHeader";
import CreateAccount from "../components/CreateAccount";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

function Register() {
  return(
    <div>
      <NavBar />
      <SectionHeader 
        titleName='Create An Account' 
        itemName='Register' 
      />
      <CreateAccount />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default Register;
