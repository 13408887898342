import NavBar from "../components/NavBar";
import DashBoard from "../components/DashBoard";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

function MyAccount() {
  return(
    <div>
      <NavBar />
      <DashBoard />
      <Footer />
    </div>
  )
}

export default MyAccount;
