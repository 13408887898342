import React, { useState } from 'react';
import {
      Row, Col, Nav, Container, 
      Button, Form, FormGroup, Label, Input, FormText,
      ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
      Card } from 'reactstrap'

import {courseData} from './CourseData';
import  SingleCard from "./SingleCard";
import './CourseSideBar.scss'

const DropDownButton = props =>{
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  return(
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret className='p-3'>
        Recent Courses
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>Popular Courses</DropdownItem>
        <DropdownItem>Recent Courses</DropdownItem>
        <DropdownItem>Featured Courses</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  )
}
class CourseSideBarRight extends React.Component{
    render(){
        return(
          <Col lg='8'>
            <Row className='align-items-center mb-3'>
              <Col lg='6' md='6' sm='12'>
                We found <strong>142</strong> courses for you
              </Col>
              <Col lg='6' md='6' sm='12' className='ordering'>
                <DropDownButton />
              </Col>
            </Row>
            <Row className='courseCard'>
            <Col lg='6'>
              <SingleCard {...courseData[0]}/>
            </Col>
            <Col lg='6'>
              <SingleCard {...courseData[1]}/>
            </Col>
            </Row>
            
            <Row>
            <Col lg='6'>
              <SingleCard {...courseData[2]}/>
            </Col>
            <Col lg='6'>
              <SingleCard {...courseData[3]}/>
            </Col>
            </Row>
            <Row>
            <Col lg='6'>
              <SingleCard {...courseData[4]}/>
            </Col>
            <Col lg='6'>
              <SingleCard {...courseData[5]}/>
            </Col>
            </Row>
            </Col>

      )
    }
}
              
export default CourseSideBarRight;