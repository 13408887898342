import NavBar from "../components/NavBar";
import SectionHeader from "../components/SectionHeader";
import ContactUs from "../components/ContactUs";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

function Courses() {
  return(
    <div>
      <NavBar />
      <SectionHeader 
        titleName='Get In Touch' 
        itemName='Contact Us'
      />
      <ContactUs />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default Courses;
