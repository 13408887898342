import React from "react";
import { Container, BreadcrumbItem, Breadcrumb } from "reactstrap";

class SectionHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {titleName, itemName} = this.props;

    return(
      <div className="wrapper">
        <Container className="section-heading">
          <h1 className="heading text-center">{titleName}</h1>
          <Breadcrumb>
            <BreadcrumbItem><a href="/Home">Home</a></BreadcrumbItem>
            <BreadcrumbItem active>{itemName}</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </div>
    )
  }
}

export default SectionHeader;
