import { courseData } from './CourseData.js'
import './SingleCard.scss'
import React, {useState} from 'react';
import {Container, Row, Col,
        Card, CardImg, CardText, CardBody,
        CardTitle, CardSubtitle, Button} from 'reactstrap'


class SingleCard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      choosingCard: props.choosingCard || 2,
      //import singleCard data from data.js
      singleCard: courseData,
    }
  }
  render(){
    let singleCard = this.props;
    return(
      <Col className='singles_items'>
        <Card className="education_block_grid style_2">
          <div class="education_block_thumb n-shadow">
            <a href="CourseDetail">
              <CardImg src={singleCard.courseImage}className="img-fluid" alt="" />
            </a>
          </div>
          <CardBody>
            <CardTitle className='education_block_body cardTitle'>
              <h4 class="bl-title">
                <a href="CourseDetail">
                {singleCard.courseDetail}</a>
              </h4>
            </CardTitle>
            <CardText className='cources_facts'>
                <ul className="cources_facts_list">
                  <li className="facts-1">{singleCard.courseFacts[0]}</li>
                  <li className="facts-2">{singleCard.courseFacts[1]}</li>
                  <li className="facts-5">{singleCard.courseFacts[2]}</li>
                </ul>
            </CardText>
            <CardTitle className='cources_info'>
              <div className="cources_info_first">
                <ul>
                  <li><strong>{singleCard.viewCounts} Views</strong></li>
                  <li className="theme-cl">{singleCard.courseDuration}</li>
                </ul>
              </div>
              <div className="cources_info_last">
                <h3>${singleCard.coursePrice}</h3>
              </div>
            </CardTitle>
            <Row className='education_block_footer'>
              <div className="education_block_author">
                <div className="path-img">
                  <a href="instructor-detail.html">
                    <CardImg src={singleCard.instructorImage} class="img-fluid" alt=""/>
                  </a>
                </div>
                <h5><a href="instructor-detail.html">{singleCard.instructor}</a></h5>
              </div>
              <span className="education_block_time"><i className="ti-calendar mr-1"></i>{singleCard.courseBlockTime} hour ago</span>
            </Row>
            </CardBody>
        </Card>
      </Col>
    )
  }
}

export default SingleCard;