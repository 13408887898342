import NavBar from "../components/NavBar";
import CourseDetail from "../components/DetailPage";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

function CourseDetails() {
  return(
    <div>
      <NavBar />
      <CourseDetail />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default CourseDetails;
