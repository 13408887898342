import React, { useState } from 'react';
import SectionHeader from "../components/SectionHeader";
import {courseData} from './CourseData';
import  SingleCard from "./SingleCard";
import {
      Row, Col, Nav, Container, 
      Breadcrumb, BreadcrumbItem,
      Card } from 'reactstrap'
import CourseSideBarLeft from './CourseSideBarLeft'
import CourseSideBarRight from './CourseSideBarRight'
import './CourseSideBar.scss'

class CourseSideBar extends React.Component{
    render(){
        return(
          <Container className="section-heading">
            {/* <h1 className="heading text-center">Courses</h1>
            <Breadcrumb>
              <BreadcrumbItem><a href="/Home">Home</a></BreadcrumbItem>
              <BreadcrumbItem active>Find Courses</BreadcrumbItem>
            </Breadcrumb> */}

            <SectionHeader 
              titleName='Courses' 
              itemName='Find Courses' 
            />

            <Row>
              <CourseSideBarLeft />
              <CourseSideBarRight />
            </Row>
          </Container>
        )
    }
}

export default CourseSideBar;