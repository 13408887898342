import NavBar from "../components/NavBar";
import SectionHeader from "../components/SectionHeader";
import Feature from "../components/Feature";
import Testimonial from "../components/Testimonial";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

function AboutUs () {
  return(
    <div>
      <NavBar />
      <SectionHeader 
        titleName='Who We Are' 
        itemName='About Us'
      />
      <Feature />
      <Testimonial />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default AboutUs;
